@font-face {
	font-family: 'After Shok';
	src: url('After_Shok-webfont.woff');
	src: url('After_Shok-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Aldo';
	src: url('Aldo_Open-webfont.woff');
	src: url('Aldo_Open-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Aleo';
	src: url('aleo-regular-webfont.woff');
	src: url('aleo-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Alex Brush';
	src: url('alexbrush-regular-webfont.woff');
	src: url('alexbrush-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}