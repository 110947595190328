.svg_size_content {
    height: 320px;
}
/*.svg_size_content svg {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
}*/

.custom-sizes .btn-primary{
    display: inline-block;
    padding: 7px 12px;
    min-width: 120px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -o-border-radius: 40px;
    border-radius: 40px;
    color: #fff;
    border: 2px solid #222222;
    background-color: #222222;
}
.custom-sizes .btn-primary:hover{
    color: #fff;
    border: 2px solid var(--primarycolor);
    background-color: var(--primarycolor);
}
.reverse-block {
    right: 0;
    top: 50%;
    margin-top: -17px;
}
.reverse-block a{
    text-decoration: none;
}
.icon-block > i:before{
    font-size: 30px;
    color: #222222;
}
.custom-sizes label {
    font-weight: 500;
}
.reverse-block:after,
.reverse-block:before {
    content: "";
    position: absolute;
    right: 15px;
    width: 2.5vw;
    border-right: 1px solid #969696;
    height: 23px;
}
.reverse-block:before{
    top: -28px;
    border-top: 1px solid #969696;
}
.reverse-block:after{
    bottom: -28px;
    border-bottom: 1px solid #969696;
}
.choose-sizes-wrapper .flaticon-left:before, 
.choose-sizes-wrapper .flaticon-right:before {
    font-size: 27px;
    color:var(--primarycolor);
}
.tabs-inner-content svg{
    overflow: visible;
}

input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  appearance: none;
  margin: 0; 
}
input[type="number"]{ 
    -moz-appearance: textfield;
}
@media only screen and (min-width: 1025px){
    .icon-block:hover > i:before{
        color:var(--primarycolor);
    }
}
@media only screen and (max-width: 991px){
    .reverse-block:after, .reverse-block:before{
        width: 3.5vw;
    }
}