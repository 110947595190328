@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body{
  font-family: 'Roboto', sans-serif;
}
:root{
  --primarycolor:#009fdd;
}
.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.tab-title h2{
  font-size: 21px;
  color: #009fdd;
  color: var(--primarycolor);
  font-weight: 600;
}
.tab-title h2::after,
.tab-title h2::before{
  content: "";
  position: absolute;
  height: 3px;
  background: #009fdd;
  background: var(--primarycolor);
  width: 40%;
  top: 50%;
  margin-top: -1.5px;
  max-width: 95px;
}
.tab-title h2::after{
  left: 100%;
  margin-left: 11px;
}
.tab-title h2::before{
  right: 100%;
  margin-right: 11px;
}

@media only screen and (max-width:767px){
  .tab-title h2{
    font-size: 18px;
  }
  .tab-title h2::after,
  .tab-title h2::before{
    width: 22%; 
  }
}
@media only screen and (max-width:576px){
  .tab-title h2{
    font-size: 17px;
  }
}
.shape-grid .shape-name {padding: 10px 0}
.shape-grid svg:hover > *,
.shape-grid.active svg > *{
    fill: var(--primarycolor);
}
.shape-grid svg{
    cursor: pointer;
}
.svg_size_content {
    height: 320px;
}
/*.svg_size_content svg {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
}*/

.custom-sizes .btn-primary{
    display: inline-block;
    padding: 7px 12px;
    min-width: 120px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 40px;
    color: #fff;
    border: 2px solid #222222;
    background-color: #222222;
}
.custom-sizes .btn-primary:hover{
    color: #fff;
    border: 2px solid var(--primarycolor);
    background-color: var(--primarycolor);
}
.reverse-block {
    right: 0;
    top: 50%;
    margin-top: -17px;
}
.reverse-block a{
    text-decoration: none;
}
.icon-block > i:before{
    font-size: 30px;
    color: #222222;
}
.custom-sizes label {
    font-weight: 500;
}
.reverse-block:after,
.reverse-block:before {
    content: "";
    position: absolute;
    right: 15px;
    width: 2.5vw;
    border-right: 1px solid #969696;
    height: 23px;
}
.reverse-block:before{
    top: -28px;
    border-top: 1px solid #969696;
}
.reverse-block:after{
    bottom: -28px;
    border-bottom: 1px solid #969696;
}
.choose-sizes-wrapper .flaticon-left:before, 
.choose-sizes-wrapper .flaticon-right:before {
    font-size: 27px;
    color:var(--primarycolor);
}
.tabs-inner-content svg{
    overflow: visible;
}

input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  appearance: none;
  margin: 0; 
}
input[type="number"]{ 
    -moz-appearance: textfield;
}
@media only screen and (min-width: 1025px){
    .icon-block:hover > i:before{
        color:var(--primarycolor);
    }
}
@media only screen and (max-width: 991px){
    .reverse-block:after, .reverse-block:before{
        width: 3.5vw;
    }
}
.sc-bxivhb svg {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: 100%;
}
.choose-items{
    /*height: 90px;*/
    background: #ffffff;
    border: 1px solid #bababa;
    padding: 10px 10px;
    border-radius: 3px;
    cursor: pointer;
}
.choose-items.active {
    border: 1px solid var(--primarycolor);
    background: var(--primarycolor);
    color: #ffffff;
}
.choose-items.active .choose-svg-box svg rect {
    fill: var(--primarycolor);
}
.choose-sizes{
    font-size: 14px;
    pointer-events: none;
}
.choose-svg-box {
    pointer-events: none;
}
.choose-svg-box #mainLayout {
    height: 45px;
    
}
.quantity-box {
    bottom: -132px;
    width: 36%;
    right: 140px;
    position: absolute;
}
.quantity-box label {
    margin: 0 10px 0 0;
}
.quantity-box input {
    width: calc(100% - 90px);
}
/*.quantity-box input[type="number"]{
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}
.quantity-box input[type="number"]::-webkit-inner-spin-button,
.quantity-box input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
}*/
@media only screen and (max-width: 1199px){
    .quantity-box {
        width: 41%;
    }
}
@media only screen and (max-width: 991px){
    .quantity-box {
        position: static;
        width: 66.667%;
    }
    .quantity-box input{
        width: 100%;
    }
    .quantity-box label{
        font-weight: 500;
        margin-bottom: 10px;
        margin-right: 0;
    }
}
@media only screen and (max-width: 767px){
    .quantity-box {
        width: 100%;
    }
}
.material-box{
    border: 2px solid #b7b7b7;
    padding: 18px 12px;
}
.material-box .media-body ul{
    padding: 0;
}
.material-box .media-body ul li{
    list-style: none;
    position: relative;
    padding-left: 17px;
    margin-bottom: 4px;
    line-height: 1;
}
.material-box .media-body ul li:before{
    content: "\2022";
    position: absolute;
    left: 0;
    top: 0;
    font-size: 31px;
    line-height: 15px;
    color: var(--primarycolor);
}
.material-choose-wrapper {
    max-height: 507px;
    overflow-y: auto;
    overflow-x: hidden;
}
@media only screen and (max-width:991px){
    .material-box video,
    .material-box img{
        height: 120px;
    }
}
@media only screen and (max-width:991px) and (min-width:767px){
    .material-box .media-body {
        float: left;
        width: 100%;
    }
}
@media only screen and (max-width:767px){
    .material-box video,
    .material-box img{
        height: 240px;
    }
}
@media only screen and (max-width:575px){
    .material-box video,
    .material-box img{
        height: 120px;
    }
}
.material-btn > .btn {
    pointer-events: none;
}
.material-btn{
    cursor: pointer;
}
.material-btn .btn{
    color: var(--primarycolor);
    background: #ffffff;
    border: 2px solid var(--primarycolor);
    border-radius: 30px;
    padding: 6px 13px;
}
.material-btn:hover .btn,
.material-btn.active .btn{
    color: #ffffff;
    border-color: var(--primarycolor);
    background-color: var(--primarycolor);
}
.price-block .material-price {
    font-size: 23px;
}
.media-body h5 {
    line-height: 1;
}
.total-price-extras{
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
}
.media-body {
    line-height: 1;
}
.material-box.extra-item-box{
    max-height: 100%;
}
.material-box .material-box-img img {
    width: 100%;
}
@media only screen and (max-width:991px){
    .material-box video,
    .material-box .material-box-img{
        height: 120px;
    }
    .material-box .material-box-img img {
        max-height: 100%;
        max-width: 100%;
        height: auto;
        width: auto;
    }
}
@media only screen and (max-width:767px){
    .material-box video,
    .material-box .material-box-img{
        height: 240px;
    }
}
@media only screen and (max-width:575px){
    .material-box video,
    .material-box .material-box-img{
        height: 120px;
    }
}
.total-price {
    font-size: 76px;
}
.total-price-label {
    font-weight: 600;
}
.total-price{
  color: var(--primarycolor);
}
.input-group-text {
    min-width: 38px;
}
.line-height-1{
    line-height: 1;
}
.design-preview-box::before {
    content: "";
    border: 2px solid var(--primarycolor);
    width: 100%;
    height: calc(100% - 11px);
    display: inline-block;
    bottom: 0;
    position: absolute;
}
.design-preview-box .tab-title h2{
    background:#f0efef;
}
.design-preview-box .tab-title h2::before,
.design-preview-box .tab-title h2::after{
    display: none;
    height: 0;
    width: 0
}
.design-preview-img {
    height: 272px;
    vertical-align: middle;
    display: table-cell;
    width: 1000px;
}
.design-preview-img .img-responsive{
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
}
@media only screen and (max-width:575px){
    .total-price{
        font-size: 14vw;
    }
}
.canvas-container{
    /*box-shadow: 0px 0px 10px rgba(0,0,0,0.3);*/
}
.canvas_elem {
    background-image: url(/static/media/bg-img.8879803f.png);
    background-size: calc(100% - 30px) auto;
    background-repeat: repeat-y;
    background-position: center center;
}
.card .card-body textarea.form-control {height: 38px}
.canvas-container + div {
    width: 100%;
    text-align: center;
}
.font-styles .dropdown-toggle,
.font-styles .dropdown-item{
    cursor: pointer;
}
.font-styles .dropdown-toggle::after{
    float: right;
    margin-top: 8px;
}
.font-size-box{
    flex: 0 0 11.5%;
    max-width: 11.5%;
}
.textedit-panel-top > .row > div:last-child .border-right {
    border-right: none !important;
}
.textedit-panel .color-picker-box{
    right: auto;
    bottom: 100%;
    left: 41%;
}
.crop-btn.btn-primary.btn{
    font-size: 18px;
}
.crop-btn.btn-primary.btn:hover{
    background: #ffffff;
    color: var(--primarycolor);
    border-color: var(--primarycolor);
}
.custom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.4rem;
    height: 1.4rem;
    margin-top: -0.45rem;
    background: var(--primarycolor);
    cursor: pointer;
}
.custom-range::-moz-range-thumb {
    width: 1.4rem;
    height: 1.4rem;
    margin-top: -0.45rem;
    background: var(--primarycolor);
    cursor: pointer;
}
@media only screen and (max-width:767px){
      .font-size-box{
            flex: 0 0 16.6%;
            max-width: 16.6%;
            border-right: 0 !important;
            border-bottom: 1px solid #dee2e6;
      }
      .font-family-box{
          border-bottom: 1px solid #dee2e6;
      }
      .textedit-panel .color-picker-box{
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
      }
      .crop-btn.btn-primary.btn{
          font-size: 16px;
      }
}
.font-style-btn .btn{
    border-left: 0;
}
.font-style-btn .btn:focus, 
.font-style-btn .btn.focus{
    box-shadow: none;
}
.font-style-btn .btn.active{
    background: #eee;
    box-shadow: -1px 2px 3px #595959 inset;
    border-left: 0;
}
.font-style-btn .color-fill > i{
    font-size:35px;
    pointer-events: visible;
}
.font-style-btn .btn > i{
    pointer-events: none;
}
@media only screen and (max-width:1199px){
    .font-style-btn .color-fill > i{
        font-size: 30px;
    }
}
.crop-container-modal.modal{
    background: rgba(0,0,0,0.5);
}
.imgedit-panel .modal-footer .btn.btn-primary{
    font-size: 17px;
    text-align: center !important;
    margin-bottom: 0 !important;
    flex-grow: inherit;
    line-height: 1;
    padding-top: 8px;
    padding-bottom: 8px;
}
.imgedit-panel .modal-footer .btn.btn-primary.btn:hover{
    background: #ffffff;
    color: var(--primarycolor);
    border-color: var(--primarycolor);
}
.img-container > img{
        max-width: 100%;
}
.img-container .cropper-container{
    margin: 0 auto;
}
.color-picker-box .close{
    opacity: 1;
    right: -8px;
    top: -9px;
}
.sweet-loading > div[class^="css-"] {height: 100px; width: 100px;}
.sweet-loading{
    width: 100%;
    height: 100%;
}
.loader-txt{
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.card-body .title {text-decoration: underline; color: var(--primarycolor); }
/*.card-body .top-section {border-bottom: 2px solid #cccccc}*/
.card-body .text-detail {color: var(--primarycolor);}
.text-detail h5 {font-weight: 600}
.margin-detail .red.margin {color: #ff0000}
.margin-detail .black.margin {color: #000000}
.margin-detail .green.margin {color: #009900}
.margin-detail .margin .border-dashed {border-top: 2px dashed;}
.card-body .customize {border-left: 2px solid #cccccc}
.customize .btn-primary.btn:hover, .tools .btn.btn-outline-primary {background: transparent; color: var(--primarycolor); border-color: var(--primarycolor);}
.customize .btn-primary.btn, .tools .fa,.tools i[class^="flaticon-"]:before {font-size: 18px;}
.tools i[class^="flaticon-"]:before{line-height: 1;}
.tools .btn {margin-right: 6px; width: calc((100% / 4) - 5px);}
.tools button.btn:nth-child(4n) {margin-right: 0;}
.tools button.btn:hover {background: var(--primarycolor);; color: #ffffff;}
.tools .btn span.label {font-weight: 600;font-size: 9px;}
.tools .btn > i,
.tools .btn > .label {
    pointer-events: none;
}
.tools .btn .fa-clone {position: relative;}
.tools .btn .fa-clone:after {position: absolute; content: "\f060"; left: 4px;right: 0;bottom: 1px;font-size: 12px;-webkit-transform: rotate(-128deg);transform: rotate(-128deg);}
.tools .btn .fa-clone.back:after {position: absolute; content: "\f061"; left: 3px;right: 0;bottom: 2px;font-size: 12px;-webkit-transform: rotate(-128deg);transform: rotate(-128deg);}
.color-picker-box{
    position: absolute;
    bottom: 15px;
    right:calc(100% - 10px);
    z-index: 1;
}
.sketch-picker{
     width: 237px !important;
}
@media only screen and (max-width:1199px){
    .customize .btn-primary.btn, .tools .fa,.tools i[class^="flaticon-"]:before {font-size: 15px;}
}
@media only screen and (max-width:991px){
    .customize .btn-primary.btn, .tools .fa,.tools i[class^="flaticon-"]:before {font-size: 18px;}
    .info-panel{
        border-top: 1px solid #dedede;
    }
}
@media only screen and (max-width:767px){
    .customize .btn-primary.btn, .tools .fa,.tools i[class^="flaticon-"]:before {font-size: 16px;}
    .card-body .customize{
        border-left: 0;
        border-top: 1px solid #dedede;
    }
    .customize .color-picker-box{
        bottom: 64px;
        right: auto;
        left: 15px;
    }
    .customize .btn-primary.btn {
        font-size: 14px;
        width: 100px;
        margin-right: 5px;
        margin-left: 5px;
        text-align: center !important;
        word-wrap: break-word;
        white-space: normal;
        padding: 5px;
        min-width: 0;
    }
    .customize .btn-primary.btn > .fa {
        font-size: 16px;
        text-align: center;
        display: block;
        margin-right: 0 !important;
    }

}
@media only screen and (max-width:479px){
    .customize .btn-primary.btn{
        font-size: 11px;
        width: 83px;
    }
}
@media only screen and (max-width:375px){
    .customize .color-picker-box{
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}
@media only screen and (max-width:374px){
    .customize .btn-primary.btn{
        font-size: 0;
        width: 65px;
    }
    .customize .btn-primary.btn > .fa{
        font-size: 20px;
    }
}
.align-buttonbox button{
    background: rgba(0,0,0,0);
    line-height: 1;
}
.align-buttonbox button > i[class^="flaticon-"]::before {
    font-size: 25px;
}
.align-buttonbox button > i{
    pointer-events: none;
}
.align-buttonbox {
    bottom: 58px;
    left: calc((100% / 4) + 10px);
    z-index: 1;
    background: #eeeeee;
}
.text-primary{
    color: var(--primarycolor) !important;
}
.align-buttonbox .text-primary:hover{
    color: #333333 !important;
}
.text-align-panel .close{
    right: -5px;
    top: -11px;
    opacity: 1;
}
.text-align-panel .close .fa {
    font-size: 18px;
}
@media only screen and (max-width:1199px){
    .align-buttonbox button > i[class^="flaticon-"]::before{
            font-size: 21px;
    }
    .text-align-panel .close{
        top: -13px;
    }   
}
.stickers-container .tabs-header .nav-item{
    width: 16.6%;
    width: calc(100% / 6);
}
.stickers-container .nav-item .nav-link {
    position: relative;
    width: 120px;
    /*width: 6.25vw;*/
    /* height: 70px; */
    background-color: #ffffff;
    /* box-shadow: inset -3px 0 0 0 var(--primarycolor), inset 3px 0 0 0 var(--primarycolor); */
    color: var(--primarycolor);
    margin: 7px auto;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    line-height: 1;
    font-size: 18px;
    font-weight: 500;
    border-radius:0;
    z-index: 1;
    cursor: pointer;
    height: 120px;
    border: 2px solid var(--primarycolor);
    border-radius: 50%;
    align-content: center;
}
.stickers-container .nav-item .active .nav-link,
.stickers-container .nav-item .active .nav-link:hover{
    background-color: var(--primarycolor);
    color: #ffffff;
    /* text-shadow: 2px 2px 2px rgba(0,0,0,0.7); */
}
.stickers-container .nav-item .nav-link:before,
.stickers-container .nav-item .nav-link:after {
    content: "";
    position: absolute;
    width:49px; 
    height:49px;
    webkit-transform-origin: 0 100%;
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    -webkit-transform: scaleX(1.73) rotate(45deg);
    transform: scaleX(1.73) rotate(45deg);
    left: 0;
    background: #ffffff;
    z-index: -1;
    display: none;
}
.stickers-container .nav-item .active .nav-link:before,
.stickers-container .nav-item .active .nav-link:after{
    background-color: var(--primarycolor);
}
.stickers-container .nav-item .nav-link:before {
    bottom: 100%;
    box-shadow: inset 0 3px 0 0 var(--primarycolor), inset 3px 0 0 0 var(--primarycolor);
}
.stickers-container .nav-item .nav-link:after {
    bottom: 0;
    box-shadow: inset 0 -3px 0 0 var(--primarycolor), inset -3px 0 0 0 var(--primarycolor);
}
.stickers-container .nav-item .nav-link i[class^='flaticon-']:before{
    font-size: 47px;
    color: var(--primarycolor);
    display: block;
    line-height: 1;
}
.stickers-container .nav-item .active .nav-link i[class^='flaticon-']:before{
    color: #ffffff;
}
.stickers-container .nav-item .nav-link i[class^='flaticon-'] {
    width: 100%;
    display: block;
    margin-bottom: 5px;
}
.stickers-container .nav-item .nav-link i.flaticon-sizes{
    margin-bottom: 0;
}
.stickers-container .nav-item .nav-link i.flaticon-sizes:before{
    font-size: 65px;
    line-height: 45px;
    margin-top: 12px;
}
.stickers-container .nav-item .nav-link i.flaticon-extra:before{
    font-size: 48px;
    margin-left: 11px;
}
.stickers-container .nav-item .nav-link i.flaticon-summary:before{
    font-size: 48px;
    margin-left: 18px;
}
.stickers-container .tabs-header .nav{
    position: relative;
}
.stickers-container .tabs-header .nav:after {
    content: "";
    position: absolute;
    top: 50%;
    background: var(--primarycolor);
    height: 3px;
    width: 83%;
    left: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
}
.tabs-header {
    position: relative;
}
.stickers-container .tabs-content{
    padding: 25px;
    background: #f0efef;
    min-height: 606px;
}
.stickers-container .tabs-footer{
    padding: 25px 0;
    background-color: #ffffff;
    border-top: none;
} 
.stickers-container .nav-item > .active{
    position: relative;
    display: inline-block;
}
.stickers-container .nav-item > .active::after {
    content: "\f111";
    position: absolute;
    font-family: "Flaticon";
    right: -36%;
    top: 50%;
    margin-top: -13px;
    font-size: 21px;
    color: var(--primarycolor);
}
.stickers-container .nav-item:last-child > .active::after{
    content: "";
    display: none;
}
.moving-tab {
    font-size: 0;
    position: absolute;
    top: 50%;
    left: 10%;
    margin-top: -9px;
    color: var(--primarycolor);
}
.moving-tab i[class^="flaticon-"]:before{
    content: "\f111";
}
.moving-tab .flaticon-summary:before{
    content: '';display: none;font-size: 0;
}
/* tabs */
.stickers-container .tabs-footer .btn,
.btn-primary.btn {
    display: inline-block;
    padding: 10px 12px;
    min-width: 140px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 40px;
    color: #fff;
    border: 2px solid var(--primarycolor);
    background-color: var(--primarycolor);

}
.stickers-container .tabs-footer .btn:hover,
.btn-primary.btn:hover{
    color: var(--primarycolor);
    border: 2px solid var(--primarycolor);
    background-color: #ffffff;
}
.stickers-container .tabs-footer .btn:focus,
.btn-primary.btn:focus,
.btn-outline-primary:focus,
.stickers-container .tabs-footer .btn:active:focus,
.stickers-container .tabs-footer .btn.active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, 
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus, 
.btn-primary:not(:disabled):not(.disabled).active:focus{
    box-shadow:none;
}
.stickers-container .tabs-footer .btn:active,
.btn-primary.btn:active,
.btn-primary:not(:disabled):not(.disabled):active, 
.btn-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active, 
.btn-outline-primary:not(:disabled):not(.disabled).active{
    background-color: var(--primarycolor);
    border-color: var(--primarycolor);
    color: #ffffff;
}
@media only screen and (min-width:768px){
    .stickers-container .nav-item .nav-link:hover{
        color: var(--primarycolor);
    }
}
@media only screen and (max-width:1200px){
    .stickers-container .nav-item > .active::after{
        right: -24%;
        margin-top: -14px;
    }
}
@media only screen and (max-width:1024px){
    .stickers-container .tabs-footer .btn:hover,
    .btn-primary.btn:hover{
        background-color: var(--primarycolor);
        color: #ffffff;
    }
}
@media only screen and (max-width:991px){
    .stickers-container .nav-item .nav-link{
        width: 11vw;
        height: 11vw;
        font-size: 1.6vw;
        margin: 1vw auto;
    }
    /* .stickers-container .nav-item .nav-link:before, 
    .stickers-container .nav-item .nav-link:after{
        width: 4.3vw;
        height: 4.3vw;
    } */
    .stickers-container .nav-item .nav-link i[class^='flaticon-']:before,
    .stickers-container .nav-item .nav-link i.flaticon-summary:before,
    .stickers-container .nav-item .nav-link i.flaticon-extra:before{
            font-size: 4.3vw;
    }
    .stickers-container .nav-item .nav-link i.flaticon-sizes:before{
        font-size: 5.5vw;
        margin-top: 1.5vw;
        line-height: 3vw;
    }
    .stickers-container .nav-item > .active::after{
        font-size: 17px;
        margin-top: -10px;
    }
    .stickers-container .tabs-footer .btn,
    .btn-primary.btn{
        padding: 9px 8px;
        min-width: 120px;
    }
}
@media only screen and (max-width:767px){
    .stickers-container .nav-item .nav-link{
        width: 7vw;
        height: 7vw;
        margin: 0vw auto;
        border: none;
        color: #656565;
    }
    .stickers-container .nav-item > .active:before {
        content: "";
        height: 3px;
        width: 100%;
        background: var(--primarycolor);
        position: absolute;
        bottom: -8px;
        left: 0;
        right: 0;
    }
    
    .stickers-container .nav-item .active .nav-link, 
    .stickers-container .nav-item .active .nav-link:hover{
        background-color:#ffffff;
        color: var(--primarycolor);
    }
    .stickers-container .nav-item .nav-link i[class^='flaticon-']:before{
        color: #656565;
    }
    
    .stickers-container .nav-item .active .nav-link i[class^='flaticon-']:before{
        color: var(--primarycolor);
    }
    .stickers-container .tabs-header .nav:after{
        height: 2px;
        margin-top: -3px;
    }
    .stickers-container .nav-item > .active::after{
        font-size: 12px;
        margin-top: -10.5px;
        right: -55%;
    }
    .stickers-container .tabs-header .nav:after{
        height:0;width: 0;display: none;
    }
}
@media only screen and (max-width:639px){
    .stickers-container .nav-item .nav-link{
        width: 12vw;
        height: 12vw;
        font-size: 2.4vw;
    }
    .stickers-container .nav-item > .active::after{
        right: -2.8vw;
    }
    .stickers-container .nav-item .nav-link i.flaticon-extra:before{
        margin-left: 2vw;
    }
    .stickers-container .nav-item .nav-link i.flaticon-summary:before{
        margin-left: 3.3vw;
    }
    .stickers-container .nav-item .nav-link i[class^='flaticon-']:before,
    .stickers-container .nav-item .nav-link i.flaticon-summary:before,
    .stickers-container .nav-item .nav-link i.flaticon-extra:before{
        font-size: 8.3vw;
    }
    .stickers-container .nav-item .nav-link i.flaticon-sizes:before{
        line-height: 7vw;
        font-size: 9.3vw;
    }
    .stickers-container .tabs-footer .btn, .btn-primary.btn {
        padding: 7px 6px;
        min-width: 105px;
    }
}
@media only screen and (max-width:479px){
    .stickers-container .tabs-content{
        padding-left: 15px;
        padding-right: 15px;
    }
}
	/*
  	Flaticon icon font: Flaticon
  	Creation date: 24/04/2019 10:32
  	*/

@font-face {
  font-family: "Flaticon";
  src: url(/static/media/Flaticon.dcf7e2d0.eot);
  src: url(/static/media/Flaticon.dcf7e2d0.eot?#iefix) format("embedded-opentype"),
       url(/static/media/Flaticon.d93a193a.woff2) format("woff2"),
       url(/static/media/Flaticon.a5a8d387.woff) format("woff"),
       url(/static/media/Flaticon.ed0d44b5.ttf) format("truetype"),
       url(/static/media/Flaticon.45f99004.svg#Flaticon) format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url(/static/media/Flaticon.45f99004.svg#Flaticon) format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-size: 20px;
  font-style: normal;
}

.flaticon-ungroup:before { content: "\f100"; }
.flaticon-horizontal-align-center:before { content: "\f101"; }
.flaticon-vertical-align-center:before { content: "\f102"; }
.flaticon-align-bottom:before { content: "\f103"; }
.flaticon-align-top:before { content: "\f104"; }
.flaticon-align-right:before { content: "\f105"; }
.flaticon-align-left:before { content: "\f106"; }
.flaticon-shapes:before { content: "\f107"; }
.flaticon-sizes:before { content: "\f108"; }
.flaticon-material:before { content: "\f109"; }
.flaticon-extra:before { content: "\f10a"; }
.flaticon-summary:before { content: "\f10b"; }
.flaticon-left:before { content: "\f10c"; }
.flaticon-right:before { content: "\f10d"; }
.flaticon-refresh-btn:before { content: "\f10e"; }
.flaticon-flip-v:before { content: "\f10f"; }
.flaticon-flip-h:before { content: "\f110"; }
.flaticon-arrow-right:before { content: "\f111"; }
.flaticon-report:before { content: "\f112"; }
@font-face {
	font-family: 'After Shok';
	src: url(/static/media/After_Shok-webfont.48e39b5e.woff);
	src: url(/static/media/After_Shok-webfont.48e39b5e.woff) format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Aldo';
	src: url(/static/media/Aldo_Open-webfont.816617ae.woff);
	src: url(/static/media/Aldo_Open-webfont.816617ae.woff) format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Aleo';
	src: url(/static/media/aleo-regular-webfont.6ffae26a.woff);
	src: url(/static/media/aleo-regular-webfont.6ffae26a.woff) format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Alex Brush';
	src: url(/static/media/alexbrush-regular-webfont.3f634c80.woff);
	src: url(/static/media/alexbrush-regular-webfont.3f634c80.woff) format('woff');
	font-weight: normal;
	font-style: normal;
}
