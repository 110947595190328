.align-buttonbox button{
    background: rgba(0,0,0,0);
    line-height: 1;
}
.align-buttonbox button > i[class^="flaticon-"]::before {
    font-size: 25px;
}
.align-buttonbox button > i{
    pointer-events: none;
}
.align-buttonbox {
    bottom: 58px;
    left: calc((100% / 4) + 10px);
    z-index: 1;
    background: #eeeeee;
}
.text-primary{
    color: var(--primarycolor) !important;
}
.align-buttonbox .text-primary:hover{
    color: #333333 !important;
}
.text-align-panel .close{
    right: -5px;
    top: -11px;
    opacity: 1;
}
.text-align-panel .close .fa {
    font-size: 18px;
}
@media only screen and (max-width:1199px){
    .align-buttonbox button > i[class^="flaticon-"]::before{
            font-size: 21px;
    }
    .text-align-panel .close{
        top: -13px;
    }   
}