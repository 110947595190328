.canvas-container{
    /*box-shadow: 0px 0px 10px rgba(0,0,0,0.3);*/
}
.canvas_elem {
    background-image: url('../DesignTool/images/bg-img.png');
    background-size: calc(100% - 30px) auto;
    background-repeat: repeat-y;
    background-position: center center;
}
.card .card-body textarea.form-control {height: 38px}
.canvas-container + div {
    width: 100%;
    text-align: center;
}