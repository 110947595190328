@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap');
body{
  font-family: 'Roboto', sans-serif;
}
:root{
  --primarycolor:#009fdd;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.tab-title h2{
  font-size: 21px;
  color: var(--primarycolor);
  font-weight: 600;
}
.tab-title h2::after,
.tab-title h2::before{
  content: "";
  position: absolute;
  height: 3px;
  background: var(--primarycolor);
  width: 40%;
  top: 50%;
  margin-top: -1.5px;
  max-width: 95px;
}
.tab-title h2::after{
  left: 100%;
  margin-left: 11px;
}
.tab-title h2::before{
  right: 100%;
  margin-right: 11px;
}

@media only screen and (max-width:767px){
  .tab-title h2{
    font-size: 18px;
  }
  .tab-title h2::after,
  .tab-title h2::before{
    width: 22%; 
  }
}
@media only screen and (max-width:576px){
  .tab-title h2{
    font-size: 17px;
  }
}