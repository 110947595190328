.material-btn > .btn {
    pointer-events: none;
}
.material-btn{
    cursor: pointer;
}
.material-btn .btn{
    color: var(--primarycolor);
    background: #ffffff;
    border: 2px solid var(--primarycolor);
    border-radius: 30px;
    padding: 6px 13px;
}
.material-btn:hover .btn,
.material-btn.active .btn{
    color: #ffffff;
    border-color: var(--primarycolor);
    background-color: var(--primarycolor);
}
.price-block .material-price {
    font-size: 23px;
}
.media-body h5 {
    line-height: 1;
}