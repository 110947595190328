.sweet-loading > div[class^="css-"] {height: 100px; width: 100px;}
.sweet-loading{
    width: 100%;
    height: 100%;
}
.loader-txt{
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}