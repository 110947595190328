.crop-container-modal.modal{
    background: rgba(0,0,0,0.5);
}
.imgedit-panel .modal-footer .btn.btn-primary{
    font-size: 17px;
    text-align: center !important;
    margin-bottom: 0 !important;
    flex-grow: inherit;
    line-height: 1;
    padding-top: 8px;
    padding-bottom: 8px;
}
.imgedit-panel .modal-footer .btn.btn-primary.btn:hover{
    background: #ffffff;
    color: var(--primarycolor);
    border-color: var(--primarycolor);
}
.img-container > img{
        max-width: 100%;
}
.img-container .cropper-container{
    margin: 0 auto;
}