.font-style-btn .btn{
    border-left: 0;
}
.font-style-btn .btn:focus, 
.font-style-btn .btn.focus{
    box-shadow: none;
}
.font-style-btn .btn.active{
    background: #eee;
    box-shadow: -1px 2px 3px #595959 inset;
    border-left: 0;
}
.font-style-btn .color-fill > i{
    font-size:35px;
    pointer-events: visible;
}
.font-style-btn .btn > i{
    pointer-events: none;
}
@media only screen and (max-width:1199px){
    .font-style-btn .color-fill > i{
        font-size: 30px;
    }
}