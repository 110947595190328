.font-styles .dropdown-toggle,
.font-styles .dropdown-item{
    cursor: pointer;
}
.font-styles .dropdown-toggle::after{
    float: right;
    margin-top: 8px;
}
.font-size-box{
    flex: 0 0 11.5%;
    max-width: 11.5%;
}
.textedit-panel-top > .row > div:last-child .border-right {
    border-right: none !important;
}
.textedit-panel .color-picker-box{
    right: auto;
    bottom: 100%;
    left: 41%;
}
.crop-btn.btn-primary.btn{
    font-size: 18px;
}
.crop-btn.btn-primary.btn:hover{
    background: #ffffff;
    color: var(--primarycolor);
    border-color: var(--primarycolor);
}
.custom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.4rem;
    height: 1.4rem;
    margin-top: -0.45rem;
    background: var(--primarycolor);
    cursor: pointer;
}
.custom-range::-moz-range-thumb {
    width: 1.4rem;
    height: 1.4rem;
    margin-top: -0.45rem;
    background: var(--primarycolor);
    cursor: pointer;
}
@media only screen and (max-width:767px){
      .font-size-box{
            flex: 0 0 16.6%;
            max-width: 16.6%;
            border-right: 0 !important;
            border-bottom: 1px solid #dee2e6;
      }
      .font-family-box{
          border-bottom: 1px solid #dee2e6;
      }
      .textedit-panel .color-picker-box{
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
      }
      .crop-btn.btn-primary.btn{
          font-size: 16px;
      }
}