.stickers-container .tabs-header .nav-item{
    width: 16.6%;
    width: calc(100% / 6);
}
.stickers-container .nav-item .nav-link {
    position: relative;
    width: 120px;
    /*width: 6.25vw;*/
    /* height: 70px; */
    background-color: #ffffff;
    /* box-shadow: inset -3px 0 0 0 var(--primarycolor), inset 3px 0 0 0 var(--primarycolor); */
    color: var(--primarycolor);
    margin: 7px auto;
    padding: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox; 
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    line-height: 1;
    font-size: 18px;
    font-weight: 500;
    border-radius:0;
    z-index: 1;
    cursor: pointer;
    height: 120px;
    border: 2px solid var(--primarycolor);
    border-radius: 50%;
    -webkit-align-content: center;
    align-content: center;
}
.stickers-container .nav-item .active .nav-link,
.stickers-container .nav-item .active .nav-link:hover{
    background-color: var(--primarycolor);
    color: #ffffff;
    /* text-shadow: 2px 2px 2px rgba(0,0,0,0.7); */
}
.stickers-container .nav-item .nav-link:before,
.stickers-container .nav-item .nav-link:after {
    content: "";
    position: absolute;
    width:49px; 
    height:49px;
    webkit-transform-origin: 0 100%;
    -moz-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleX(1.73) rotate(45deg);
    -moz-transform: scaleX(1.73) rotate(45deg);
    -ms-transform: scaleX(1.73) rotate(45deg);
    transform: scaleX(1.73) rotate(45deg);
    left: 0;
    background: #ffffff;
    z-index: -1;
    display: none;
}
.stickers-container .nav-item .active .nav-link:before,
.stickers-container .nav-item .active .nav-link:after{
    background-color: var(--primarycolor);
}
.stickers-container .nav-item .nav-link:before {
    bottom: 100%;
    box-shadow: inset 0 3px 0 0 var(--primarycolor), inset 3px 0 0 0 var(--primarycolor);
}
.stickers-container .nav-item .nav-link:after {
    bottom: 0;
    box-shadow: inset 0 -3px 0 0 var(--primarycolor), inset -3px 0 0 0 var(--primarycolor);
}
.stickers-container .nav-item .nav-link i[class^='flaticon-']:before{
    font-size: 47px;
    color: var(--primarycolor);
    display: block;
    line-height: 1;
}
.stickers-container .nav-item .active .nav-link i[class^='flaticon-']:before{
    color: #ffffff;
}
.stickers-container .nav-item .nav-link i[class^='flaticon-'] {
    width: 100%;
    display: block;
    margin-bottom: 5px;
}
.stickers-container .nav-item .nav-link i.flaticon-sizes{
    margin-bottom: 0;
}
.stickers-container .nav-item .nav-link i.flaticon-sizes:before{
    font-size: 65px;
    line-height: 45px;
    margin-top: 12px;
}
.stickers-container .nav-item .nav-link i.flaticon-extra:before{
    font-size: 48px;
    margin-left: 11px;
}
.stickers-container .nav-item .nav-link i.flaticon-summary:before{
    font-size: 48px;
    margin-left: 18px;
}
.stickers-container .tabs-header .nav{
    position: relative;
}
.stickers-container .tabs-header .nav:after {
    content: "";
    position: absolute;
    top: 50%;
    background: var(--primarycolor);
    height: 3px;
    width: 83%;
    left: 50%;
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -o-transform: translate(-50%);
    transform: translate(-50%);
}
.tabs-header {
    position: relative;
}
.stickers-container .tabs-content{
    padding: 25px;
    background: #f0efef;
    min-height: 606px;
}
.stickers-container .tabs-footer{
    padding: 25px 0;
    background-color: #ffffff;
    border-top: none;
} 
.stickers-container .nav-item > .active{
    position: relative;
    display: inline-block;
}
.stickers-container .nav-item > .active::after {
    content: "\f111";
    position: absolute;
    font-family: "Flaticon";
    right: -36%;
    top: 50%;
    margin-top: -13px;
    font-size: 21px;
    color: var(--primarycolor);
}
.stickers-container .nav-item:last-child > .active::after{
    content: "";
    display: none;
}
.moving-tab {
    font-size: 0;
    position: absolute;
    top: 50%;
    left: 10%;
    margin-top: -9px;
    color: var(--primarycolor);
}
.moving-tab i[class^="flaticon-"]:before{
    content: "\f111";
}
.moving-tab .flaticon-summary:before{
    content: '';display: none;font-size: 0;
}
/* tabs */
.stickers-container .tabs-footer .btn,
.btn-primary.btn {
    display: inline-block;
    padding: 10px 12px;
    min-width: 140px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -o-border-radius: 40px;
    border-radius: 40px;
    color: #fff;
    border: 2px solid var(--primarycolor);
    background-color: var(--primarycolor);

}
.stickers-container .tabs-footer .btn:hover,
.btn-primary.btn:hover{
    color: var(--primarycolor);
    border: 2px solid var(--primarycolor);
    background-color: #ffffff;
}
.stickers-container .tabs-footer .btn:focus,
.btn-primary.btn:focus,
.btn-outline-primary:focus,
.stickers-container .tabs-footer .btn:active:focus,
.stickers-container .tabs-footer .btn.active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, 
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus, 
.btn-primary:not(:disabled):not(.disabled).active:focus{
    box-shadow:none;
}
.stickers-container .tabs-footer .btn:active,
.btn-primary.btn:active,
.btn-primary:not(:disabled):not(.disabled):active, 
.btn-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active, 
.btn-outline-primary:not(:disabled):not(.disabled).active{
    background-color: var(--primarycolor);
    border-color: var(--primarycolor);
    color: #ffffff;
}
@media only screen and (min-width:768px){
    .stickers-container .nav-item .nav-link:hover{
        color: var(--primarycolor);
    }
}
@media only screen and (max-width:1200px){
    .stickers-container .nav-item > .active::after{
        right: -24%;
        margin-top: -14px;
    }
}
@media only screen and (max-width:1024px){
    .stickers-container .tabs-footer .btn:hover,
    .btn-primary.btn:hover{
        background-color: var(--primarycolor);
        color: #ffffff;
    }
}
@media only screen and (max-width:991px){
    .stickers-container .nav-item .nav-link{
        width: 11vw;
        height: 11vw;
        font-size: 1.6vw;
        margin: 1vw auto;
    }
    /* .stickers-container .nav-item .nav-link:before, 
    .stickers-container .nav-item .nav-link:after{
        width: 4.3vw;
        height: 4.3vw;
    } */
    .stickers-container .nav-item .nav-link i[class^='flaticon-']:before,
    .stickers-container .nav-item .nav-link i.flaticon-summary:before,
    .stickers-container .nav-item .nav-link i.flaticon-extra:before{
            font-size: 4.3vw;
    }
    .stickers-container .nav-item .nav-link i.flaticon-sizes:before{
        font-size: 5.5vw;
        margin-top: 1.5vw;
        line-height: 3vw;
    }
    .stickers-container .nav-item > .active::after{
        font-size: 17px;
        margin-top: -10px;
    }
    .stickers-container .tabs-footer .btn,
    .btn-primary.btn{
        padding: 9px 8px;
        min-width: 120px;
    }
}
@media only screen and (max-width:767px){
    .stickers-container .nav-item .nav-link{
        width: 7vw;
        height: 7vw;
        margin: 0vw auto;
        border: none;
        color: #656565;
    }
    .stickers-container .nav-item > .active:before {
        content: "";
        height: 3px;
        width: 100%;
        background: var(--primarycolor);
        position: absolute;
        bottom: -8px;
        left: 0;
        right: 0;
    }
    
    .stickers-container .nav-item .active .nav-link, 
    .stickers-container .nav-item .active .nav-link:hover{
        background-color:#ffffff;
        color: var(--primarycolor);
    }
    .stickers-container .nav-item .nav-link i[class^='flaticon-']:before{
        color: #656565;
    }
    
    .stickers-container .nav-item .active .nav-link i[class^='flaticon-']:before{
        color: var(--primarycolor);
    }
    .stickers-container .tabs-header .nav:after{
        height: 2px;
        margin-top: -3px;
    }
    .stickers-container .nav-item > .active::after{
        font-size: 12px;
        margin-top: -10.5px;
        right: -55%;
    }
    .stickers-container .tabs-header .nav:after{
        height:0;width: 0;display: none;
    }
}
@media only screen and (max-width:639px){
    .stickers-container .nav-item .nav-link{
        width: 12vw;
        height: 12vw;
        font-size: 2.4vw;
    }
    .stickers-container .nav-item > .active::after{
        right: -2.8vw;
    }
    .stickers-container .nav-item .nav-link i.flaticon-extra:before{
        margin-left: 2vw;
    }
    .stickers-container .nav-item .nav-link i.flaticon-summary:before{
        margin-left: 3.3vw;
    }
    .stickers-container .nav-item .nav-link i[class^='flaticon-']:before,
    .stickers-container .nav-item .nav-link i.flaticon-summary:before,
    .stickers-container .nav-item .nav-link i.flaticon-extra:before{
        font-size: 8.3vw;
    }
    .stickers-container .nav-item .nav-link i.flaticon-sizes:before{
        line-height: 7vw;
        font-size: 9.3vw;
    }
    .stickers-container .tabs-footer .btn, .btn-primary.btn {
        padding: 7px 6px;
        min-width: 105px;
    }
}
@media only screen and (max-width:479px){
    .stickers-container .tabs-content{
        padding-left: 15px;
        padding-right: 15px;
    }
}