.card-body .title {text-decoration: underline; color: var(--primarycolor); }
/*.card-body .top-section {border-bottom: 2px solid #cccccc}*/
.card-body .text-detail {color: var(--primarycolor);}
.text-detail h5 {font-weight: 600}
.margin-detail .red.margin {color: #ff0000}
.margin-detail .black.margin {color: #000000}
.margin-detail .green.margin {color: #009900}
.margin-detail .margin .border-dashed {border-top: 2px dashed;}
.card-body .customize {border-left: 2px solid #cccccc}
.customize .btn-primary.btn:hover, .tools .btn.btn-outline-primary {background: transparent; color: var(--primarycolor); border-color: var(--primarycolor);}
.customize .btn-primary.btn, .tools .fa,.tools i[class^="flaticon-"]:before {font-size: 18px;}
.tools i[class^="flaticon-"]:before{line-height: 1;}
.tools .btn {margin-right: 6px; width: calc((100% / 4) - 5px);}
.tools button.btn:nth-child(4n) {margin-right: 0;}
.tools button.btn:hover {background: var(--primarycolor);; color: #ffffff;}
.tools .btn span.label {font-weight: 600;font-size: 9px;}
.tools .btn > i,
.tools .btn > .label {
    pointer-events: none;
}
.tools .btn .fa-clone {position: relative;}
.tools .btn .fa-clone:after {position: absolute; content: "\f060"; left: 4px;right: 0;bottom: 1px;font-size: 12px;transform: rotate(-128deg);}
.tools .btn .fa-clone.back:after {position: absolute; content: "\f061"; left: 3px;right: 0;bottom: 2px;font-size: 12px;transform: rotate(-128deg);}
.color-picker-box{
    position: absolute;
    bottom: 15px;
    right:calc(100% - 10px);
    z-index: 1;
}
.sketch-picker{
     width: 237px !important;
}
@media only screen and (max-width:1199px){
    .customize .btn-primary.btn, .tools .fa,.tools i[class^="flaticon-"]:before {font-size: 15px;}
}
@media only screen and (max-width:991px){
    .customize .btn-primary.btn, .tools .fa,.tools i[class^="flaticon-"]:before {font-size: 18px;}
    .info-panel{
        border-top: 1px solid #dedede;
    }
}
@media only screen and (max-width:767px){
    .customize .btn-primary.btn, .tools .fa,.tools i[class^="flaticon-"]:before {font-size: 16px;}
    .card-body .customize{
        border-left: 0;
        border-top: 1px solid #dedede;
    }
    .customize .color-picker-box{
        bottom: 64px;
        right: auto;
        left: 15px;
    }
    .customize .btn-primary.btn {
        font-size: 14px;
        width: 100px;
        margin-right: 5px;
        margin-left: 5px;
        text-align: center !important;
        word-wrap: break-word;
        white-space: normal;
        padding: 5px;
        min-width: 0;
    }
    .customize .btn-primary.btn > .fa {
        font-size: 16px;
        text-align: center;
        display: block;
        margin-right: 0 !important;
    }

}
@media only screen and (max-width:479px){
    .customize .btn-primary.btn{
        font-size: 11px;
        width: 83px;
    }
}
@media only screen and (max-width:375px){
    .customize .color-picker-box{
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}
@media only screen and (max-width:374px){
    .customize .btn-primary.btn{
        font-size: 0;
        width: 65px;
    }
    .customize .btn-primary.btn > .fa{
        font-size: 20px;
    }
}