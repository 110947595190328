.total-price-extras{
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
}
.media-body {
    line-height: 1;
}
.material-box.extra-item-box{
    max-height: 100%;
}
.material-box .material-box-img img {
    width: 100%;
}
@media only screen and (max-width:991px){
    .material-box video,
    .material-box .material-box-img{
        height: 120px;
    }
    .material-box .material-box-img img {
        max-height: 100%;
        max-width: 100%;
        height: auto;
        width: auto;
    }
}
@media only screen and (max-width:767px){
    .material-box video,
    .material-box .material-box-img{
        height: 240px;
    }
}
@media only screen and (max-width:575px){
    .material-box video,
    .material-box .material-box-img{
        height: 120px;
    }
}