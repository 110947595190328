.material-box{
    border: 2px solid #b7b7b7;
    padding: 18px 12px;
}
.material-box .media-body ul{
    padding: 0;
}
.material-box .media-body ul li{
    list-style: none;
    position: relative;
    padding-left: 17px;
    margin-bottom: 4px;
    line-height: 1;
}
.material-box .media-body ul li:before{
    content: "\2022";
    position: absolute;
    left: 0;
    top: 0;
    font-size: 31px;
    line-height: 15px;
    color: var(--primarycolor);
}
.material-choose-wrapper {
    max-height: 507px;
    overflow-y: auto;
    overflow-x: hidden;
}
@media only screen and (max-width:991px){
    .material-box video,
    .material-box img{
        height: 120px;
    }
}
@media only screen and (max-width:991px) and (min-width:767px){
    .material-box .media-body {
        float: left;
        width: 100%;
    }
}
@media only screen and (max-width:767px){
    .material-box video,
    .material-box img{
        height: 240px;
    }
}
@media only screen and (max-width:575px){
    .material-box video,
    .material-box img{
        height: 120px;
    }
}