.sc-bxivhb svg {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: 100%;
}
.choose-items{
    /*height: 90px;*/
    background: #ffffff;
    border: 1px solid #bababa;
    padding: 10px 10px;
    border-radius: 3px;
    cursor: pointer;
}
.choose-items.active {
    border: 1px solid var(--primarycolor);
    background: var(--primarycolor);
    color: #ffffff;
}
.choose-items.active .choose-svg-box svg rect {
    fill: var(--primarycolor);
}
.choose-sizes{
    font-size: 14px;
    pointer-events: none;
}
.choose-svg-box {
    pointer-events: none;
}
.choose-svg-box #mainLayout {
    height: 45px;
    
}
.quantity-box {
    bottom: -132px;
    width: 36%;
    right: 140px;
    position: absolute;
}
.quantity-box label {
    margin: 0 10px 0 0;
}
.quantity-box input {
    width: calc(100% - 90px);
}
/*.quantity-box input[type="number"]{
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}
.quantity-box input[type="number"]::-webkit-inner-spin-button,
.quantity-box input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
}*/
@media only screen and (max-width: 1199px){
    .quantity-box {
        width: 41%;
    }
}
@media only screen and (max-width: 991px){
    .quantity-box {
        position: static;
        width: 66.667%;
    }
    .quantity-box input{
        width: 100%;
    }
    .quantity-box label{
        font-weight: 500;
        margin-bottom: 10px;
        margin-right: 0;
    }
}
@media only screen and (max-width: 767px){
    .quantity-box {
        width: 100%;
    }
}