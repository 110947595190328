	/*
  	Flaticon icon font: Flaticon
  	Creation date: 24/04/2019 10:32
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../src/fonts/flaticon/Flaticon.eot");
  src: url("../src/fonts/flaticon/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../src/fonts/flaticon/Flaticon.woff2") format("woff2"),
       url("../src/fonts/flaticon/Flaticon.woff") format("woff"),
       url("../src/fonts/flaticon/Flaticon.ttf") format("truetype"),
       url("../src/fonts/flaticon/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../src/fonts/flaticon/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-size: 20px;
  font-style: normal;
}

.flaticon-ungroup:before { content: "\f100"; }
.flaticon-horizontal-align-center:before { content: "\f101"; }
.flaticon-vertical-align-center:before { content: "\f102"; }
.flaticon-align-bottom:before { content: "\f103"; }
.flaticon-align-top:before { content: "\f104"; }
.flaticon-align-right:before { content: "\f105"; }
.flaticon-align-left:before { content: "\f106"; }
.flaticon-shapes:before { content: "\f107"; }
.flaticon-sizes:before { content: "\f108"; }
.flaticon-material:before { content: "\f109"; }
.flaticon-extra:before { content: "\f10a"; }
.flaticon-summary:before { content: "\f10b"; }
.flaticon-left:before { content: "\f10c"; }
.flaticon-right:before { content: "\f10d"; }
.flaticon-refresh-btn:before { content: "\f10e"; }
.flaticon-flip-v:before { content: "\f10f"; }
.flaticon-flip-h:before { content: "\f110"; }
.flaticon-arrow-right:before { content: "\f111"; }
.flaticon-report:before { content: "\f112"; }