.total-price {
    font-size: 76px;
}
.total-price-label {
    font-weight: 600;
}
.total-price{
  color: var(--primarycolor);
}
.input-group-text {
    min-width: 38px;
}
.line-height-1{
    line-height: 1;
}
.design-preview-box::before {
    content: "";
    border: 2px solid var(--primarycolor);
    width: 100%;
    height: calc(100% - 11px);
    display: inline-block;
    bottom: 0;
    position: absolute;
}
.design-preview-box .tab-title h2{
    background:#f0efef;
}
.design-preview-box .tab-title h2::before,
.design-preview-box .tab-title h2::after{
    display: none;
    height: 0;
    width: 0
}
.design-preview-img {
    height: 272px;
    vertical-align: middle;
    display: table-cell;
    width: 1000px;
}
.design-preview-img .img-responsive{
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
}
@media only screen and (max-width:575px){
    .total-price{
        font-size: 14vw;
    }
}